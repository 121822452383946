export const menuItems = [{
        id: 1,
        label: 'menuitems.dashboard.text',
        icon: 'uil-home-alt',
        link: '/'
    },
    {
        id: 86,
        label: 'menuitems.chat.text',
        icon: 'mdi mdi-chat-outline',
        link: '/chat'
    },
    {
        id: 87,
        label: 'menuitems.odbMaps.text',
        icon: 'mdi mdi-map',
        link: '/coverage-maps'
    },
    {
        id: 88,
        label: 'menuitems.customer.text',
        icon: 'fas fa-users',
        subItems: [{
                id: 89,
                label: 'menuitems.customer.list.registrasi',
                link: '/customer/registration'
            },
            {
                id: 90,
                label: 'menuitems.customer.list.applicant',
                link: '/customer/applicant'
            },
            {
                id:91,
                label:'menuitems.customer.list.invoicePelanggan',
                link: '/invoices/list'
            },
            {
                id:92,
                label:'menuitems.customer.list.listPelanggan',
                link: '/customer/customer-list'
            },
        ]
    },
    {
        id: 89,
        label: 'menuitems.ticket.text',
        icon: 'uil-ticket',
        link: '/ticket',
        subItems:[{
            id:92,
            label:'menuitems.ticket.list.dashboard',
            link: '/dashboard-ticket'
        },
        {
            id:93,
            label:'menuitems.ticket.list.create',
            link: '/ticket'
        },
        ]
    },
    // {
    //     id: 2,
    //     label: 'menuitems.uielements.text',
    //     icon: 'uil-flask',
    //     subItems: [{
    //             id: 3,
    //             label: 'menuitems.uielements.list.alerts',
    //             link: '/ui/alerts'
    //         },
    //         {
    //             id: 4,
    //             label: 'menuitems.uielements.list.buttons',
    //             link: '/ui/buttons'
    //         },
    //         {
    //             id: 5,
    //             label: 'menuitems.uielements.list.cards',
    //             link: '/ui/cards'
    //         },
    //         {
    //             id: 6,
    //             label: 'menuitems.uielements.list.carousel',
    //             link: '/ui/carousel'
    //         },
    //         {
    //             id: 7,
    //             label: 'menuitems.uielements.list.dropdowns',
    //             link: '/ui/dropdown'
    //         },
    //         {
    //             id: 8,
    //             label: 'menuitems.uielements.list.grid',
    //             link: '/ui/grid'
    //         },
    //         {
    //             id: 9,
    //             label: 'menuitems.uielements.list.images',
    //             link: '/ui/images'
    //         },
    //         {
    //             id: 10,
    //             label: 'menuitems.uielements.list.lightbox',
    //             link: '/ui/lightbox'
    //         },
    //         {
    //             id: 11,
    //             label: 'menuitems.uielements.list.modals',
    //             link: '/ui/modals'
    //         },
    //         {
    //             id: 12,
    //             label: 'menuitems.uielements.list.rangeslider',
    //             link: '/ui/rangeslider'
    //         },
    //         {
    //             id: 13,
    //             label: 'menuitems.uielements.list.progressbar',
    //             link: '/ui/progressbar'
    //         },
    //         {
    //             id: 14,
    //             label: 'menuitems.uielements.list.placeholder',
    //             link: '/ui/placeholder'
    //         },
    //         {
    //             id: 15,
    //             label: 'menuitems.uielements.list.sweetalert',
    //             link: '/ui/sweet-alert'
    //         },
    //         {
    //             id: 16,
    //             label: 'menuitems.uielements.list.tabs',
    //             link: '/ui/tabs-accordions'
    //         },
    //         {
    //             id: 17,
    //             label: 'menuitems.uielements.list.typography',
    //             link: '/ui/typography'
    //         },
    //         {
    //             id: 18,
    //             label: 'menuitems.uielements.list.video',
    //             link: '/ui/video'
    //         },
    //         {
    //             id: 19,
    //             label: 'menuitems.uielements.list.general',
    //             link: '/ui/general'
    //         },
    //         {
    //             id: 20,
    //             label: "menuitems.uielements.list.colors",
    //             link: "/ui/colors"
    //         },
    //         {
    //             id: 20,
    //             label: 'menuitems.uielements.list.rating',
    //             link: '/ui/rating'
    //         }
    //     ]
    // },
    // {
    //     id: 22,
    //     label: 'menuitems.apps.text',
    //     icon: 'uil-apps',
    //     subItems: [{
    //             id: 23,
    //             label: 'menuitems.calendar.text',
    //             link: '/calendar'
    //         },
    //         {
    //             id: 24,
    //             label: 'menuitems.chat.text',
    //             link: '/chat'
    //         },
    //         {
    //             id: 25,
    //             label: 'menuitems.email.text',
    //             subItems: [{
    //                     id: 26,
    //                     label: 'menuitems.email.list.inbox',
    //                     link: '/email/inbox'
    //                 },
    //                 {
    //                     id: 27,
    //                     label: 'menuitems.email.list.reademail',
    //                     link: '/email/reademail/1'
    //                 }
    //             ]
    //         },
    //         {
    //             id: 28,
    //             label: 'menuitems.ecommerce.text',
    //             subItems: [{
    //                     id: 29,
    //                     label: 'menuitems.ecommerce.list.products',
    //                     link: '/ecommerce/products'
    //                 },
    //                 {
    //                     id: 30,
    //                     label: 'menuitems.ecommerce.list.productdetail',
    //                     link: '/ecommerce/product-detail/1'
    //                 },
    //                 {
    //                     id: 31,
    //                     label: 'menuitems.ecommerce.list.orders',
    //                     link: '/ecommerce/orders'
    //                 },
    //                 {
    //                     id: 32,
    //                     label: 'menuitems.ecommerce.list.customers',
    //                     link: '/ecommerce/customers'
    //                 },
    //                 {
    //                     id: 33,
    //                     label: 'menuitems.ecommerce.list.cart',
    //                     link: '/ecommerce/cart'
    //                 },
    //                 {
    //                     id: 34,
    //                     label: 'menuitems.ecommerce.list.checkout',
    //                     link: '/ecommerce/checkout'
    //                 },
    //                 {
    //                     id: 35,
    //                     label: 'menuitems.ecommerce.list.shops',
    //                     link: '/ecommerce/shops'
    //                 },
    //                 {
    //                     id: 36,
    //                     label: 'menuitems.ecommerce.list.addproduct',
    //                     link: '/ecommerce/add-product'
    //                 }
    //             ]
    //         },
    //         {
    //             id: 37,
    //             label: 'menuitems.invoices.text',
    //             subItems: [{
    //                     id: 38,
    //                     label: 'menuitems.invoices.list.invoicelist',
    //                     link: '/invoices/list'
    //                 },
    //                 {
    //                     id: 39,
    //                     label: 'menuitems.invoices.list.invoicedetail',
    //                     link: '/invoices/detail'
    //                 },
    //             ]
    //         },
    //         {
    //             id: 40,
    //             label: 'menuitems.contacts.text',
    //             icon: 'bxs-user-detail',
    //             subItems: [{
    //                     id: 41,
    //                     label: 'menuitems.contacts.list.usergrid',
    //                     link: '/contacts/grid'
    //                 },
    //                 {
    //                     id: 42,
    //                     label: 'menuitems.contacts.list.userlist',
    //                     link: '/contacts/list'
    //                 },
    //                 {
    //                     id: 43,
    //                     label: 'menuitems.contacts.list.profile',
    //                     link: '/contacts/profile'
    //                 }
    //             ]
    //         },
    //     ]
    // },
    // {
    //     id: 44,
    //     label: 'menuitems.components.text',
    //     icon: 'uil-layers',
    //     subItems: [{
    //             id: 45,
    //             label: 'menuitems.forms.text',
    //             subItems: [{
    //                     id: 46,
    //                     label: 'menuitems.forms.list.elements',
    //                     link: '/user-setting'
    //                 },
    //                 {
    //                     id: 47,
    //                     label: 'menuitems.forms.list.validation',
    //                     link: '/form/validation'
    //                 },
    //                 {
    //                     id: 48,
    //                     label: 'menuitems.forms.list.advanced',
    //                     link: '/form/advanced'
    //                 },
    //                 {
    //                     id: 49,
    //                     label: 'menuitems.forms.list.editor',
    //                     link: '/form/editor'
    //                 },
    //                 {
    //                     id: 50,
    //                     label: 'menuitems.forms.list.fileupload',
    //                     link: '/form/upload'
    //                 },
    //                 {
    //                     id: 51,
    //                     label: "menuitems.forms.list.repeater",
    //                     link: "/form/repeater",
    //                     parentId: 79
    //                 },
    //                 {
    //                     id: 52,
    //                     label: 'menuitems.forms.list.wizard',
    //                     link: '/form/wizard'
    //                 },
    //                 {
    //                     id: 53,
    //                     label: 'menuitems.forms.list.mask',
    //                     link: '/form/mask'
    //                 }
    //             ]
    //         },
    //         {
    //             id: 54,
    //             label: 'menuitems.tables.text',
    //             subItems: [{
    //                     id: 55,
    //                     label: 'menuitems.tables.list.basic',
    //                     link: '/tables/basic'
    //                 },
    //                 {
    //                     id: 56,
    //                     label: 'menuitems.tables.list.advanced',
    //                     link: '/tables/advanced'
    //                 }
    //             ]
    //         },
    //         {
    //             id: 57,
    //             label: 'menuitems.charts.text',
    //             subItems: [{
    //                     id: 58,
    //                     label: 'menuitems.charts.list.apex',
    //                     link: '/charts/apex'
    //                 },
    //                 {
    //                     id: 59,
    //                     label: 'menuitems.charts.list.chartjs',
    //                     link: '/charts/chartjs'
    //                 },
    //                 {
    //                     id: 60,
    //                     label: 'menuitems.charts.list.chartist',
    //                     link: '/charts/chartist'
    //                 },
    //                 {
    //                     id: 61,
    //                     label: 'menuitems.charts.list.echart',
    //                     link: '/charts/echart'
    //                 },
    //             ]
    //         },
    //         {
    //             id: 62,
    //             label: 'menuitems.icons.text',
    //             subItems: [{
    //                     id: 63,
    //                     label: "menuitems.icons.list.unicons",
    //                     link: "/icons/unicons"
    //                 },
    //                 {
    //                     id: 64,
    //                     label: "menuitems.icons.list.boxicons",
    //                     link: "/icons/boxicons"
    //                 },
    //                 {
    //                     id: 65,
    //                     label: "menuitems.icons.list.materialdesign",
    //                     link: "/icons/materialdesign"
    //                 },
    //                 {
    //                     id: 66,
    //                     label: "menuitems.icons.list.dripicons",
    //                     link: "/icons/dripicons"
    //                 },
    //                 {
    //                     id: 67,
    //                     label: "menuitems.icons.list.fontawesome",
    //                     link: "/icons/fontawesome"
    //                 }
    //             ]
    //         },
    //         {
    //             id: 68,
    //             label: 'menuitems.maps.text',
    //             subItems: [{
    //                     id: 69,
    //                     label: 'menuitems.maps.list.googlemap',
    //                     link: '/maps/google'
    //                 },
    //                 {
    //                     id: 70,
    //                     label: 'menuitems.maps.list.leafletmap',
    //                     link: '/maps/leaflet'
    //                 }
    //             ]
    //         }
    //     ]
    // },
    // {
    //     id: 71,
    //     label: 'menuitems.pages.text',
    //     icon: 'uil-copy',
    //     subItems: [{
    //             id: 72,
    //             label: 'menuitems.authentication.text',
    //             subItems: [{
    //                     id: 73,
    //                     label: 'menuitems.authentication.list.login',
    //                     link: '/auth/login-1'
    //                 },
    //                 {
    //                     id: 74,
    //                     label: 'menuitems.authentication.list.register',
    //                     link: '/auth/register-1'
    //                 },
    //                 {
    //                     id: 75,
    //                     label: 'menuitems.authentication.list.recoverpwd',
    //                     link: '/auth/recoverpwd'
    //                 },
    //                 {
    //                     id: 76,
    //                     label: 'menuitems.authentication.list.lockscreen',
    //                     link: '/auth/lock-screen'
    //                 },
    //             ]
    //         },
    //         {
    //             id: 77,
    //             label: 'menuitems.utility.text',
    //             subItems: [{
    //                     id: 78,
    //                     label: 'menuitems.utility.list.starter',
    //                     link: '/utility/starter'
    //                 },
    //                 {
    //                     id: 79,
    //                     label: 'menuitems.utility.list.maintenance',
    //                     link: '/utility/maintenance'
    //                 },
    //                 {
    //                     id: 80,
    //                     label: 'menuitems.utility.list.comingsoon',
    //                     link: '/utility/comingsoon'
    //                 },
    //                 {
    //                     id: 81,
    //                     label: 'menuitems.utility.list.timeline',
    //                     link: '/utility/timeline'
    //                 },
    //                 {
    //                     id: 82,
    //                     label: 'menuitems.utility.list.faqs',
    //                     link: '/utility/faqs'
    //                 },
    //                 {
    //                     id: 83,
    //                     label: 'menuitems.utility.list.pricing',
    //                     link: '/utility/pricing'
    //                 },
    //                 {
    //                     id: 84,
    //                     label: 'menuitems.utility.list.error404',
    //                     link: '/utility/404'
    //                 },
    //                 {
    //                     id: 85,
    //                     label: 'menuitems.utility.list.error500',
    //                     link: '/utility/500'
    //                 }
    //             ]
    //         }
    //     ]
    // }
]